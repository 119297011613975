const baseUrl = "https://www.forgivemefather.io/";
var bless = new Audio("/sfx/bless.mp3");
window.addEventListener("load", checkAuthStatus);
// Redirect to Twitter authentication if not authenticated
function animateText(targetId, text, delay = 100) {
  const el = document.getElementById(targetId);

  if (!el) {
    console.error(`Element with ID "${targetId}" not found.`);
    return;
  }

  // Clear any existing content in the target element
  el.innerHTML = "";

  // Split the text into characters
  let str = text.split("");

  // Define a self-contained animation function
  (function animate() {
    if (str.length > 0) {
      el.innerHTML += str.shift(); // Add one character at a time
      setTimeout(animate, delay); // Schedule the next iteration
    }
  })();
}
// CONNECT TWITTER
document
  .getElementById("connectTwitter")
  .addEventListener("click", async () => {
    try {
      const response = await fetch(`${baseUrl}/api/auth`);
      const data = await response.json();
      window.location.href = data.url; // Redirects to Twitter login URL
    } catch (error) {
      console.error("Error initiating authentication:", error);
    }
  });

// Check if user is authenticated
async function checkAuthStatus() {
  try {
    const response = await fetch(`${baseUrl}/api/auth-status`, {
      method: "GET",
      credentials: "include",
    });
    const data = await response.json();

    if (data.authenticated) {
      document.getElementById("login-step").classList.add("none");
      document.getElementById("tweet-step").classList.remove("none");
      animateText("speechtextid", "Tell me my child how can I help you?", 100);
      window.isAuthenticated = true;
    } else {
      window.isAuthenticated = false;
      animateText("speechtextid", "Welcome my child...", 100);
    }
  } catch (error) {
    console.error("Error checking authentication status:", error);
  }
}
// PREY OPEN NEW TAB FOR X
document.addEventListener("DOMContentLoaded", function () {
  const sacrificeButton = document.getElementById("confessButton");

  if (sacrificeButton) {
    sacrificeButton.addEventListener("click", function () {
      // Define the tweet content
      const tweetText = encodeURIComponent(
        "Forgive me Father I have sinned @ForgiveMeAi #ForgiveMeAi"
      );

      // Construct the Twitter web intent URL
      const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;

      // Open a popup window with specified dimensions
      const width = 600;
      const height = 400;
      const left = (screen.width - width) / 2;
      const top = (screen.height - height) / 2;

      window.open(
        twitterUrl,
        "TweetWindow",
        `width=${width},height=${height},top=${top},left=${left},toolbar=no,menubar=no,scrollbars=no,resizable=no,status=no`
      );
    });
  } else {
    console.warn("Sacrifice button not found.");
  }
});

// TWEET CHECK
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}
document.addEventListener("DOMContentLoaded", function () {
  const tweetForm = document.getElementById("tweetCheck");
  const checkButton = document.getElementById("check-button");
  tweetForm.addEventListener("submit", async function (event) {
    event.preventDefault();

    // If cooldown is active, prevent further actions
    if (checkButton.disabled) {
      console.log("Please wait for the cooldown to finish.", "white");
      return;
    }

    const tweetUrl = validateTweetUrl();
    if (!tweetUrl) return;
    const tweetUsername = extractUsernameFromTweetUrl(tweetUrl);
    const cookieUsername = getCookie("username");

    if (!cookieUsername) {
      console.log("No username found in cookies. Please log in.", "red");
      return;
    }
    if (tweetUsername !== cookieUsername) {
      console.log(
        "Username mismatch. You are not the owner of this tweet.",
        "red"
      );
      return;
    }
    console.log("PASS TO ANOTHER STEP");
    document.getElementById("tweet-step").classList.add("none");
    document.getElementById("confess-box").classList.remove("none");
    animateText("speechtextid", "Tell me my child what have you done?", 100);
  });
  function extractUsernameFromTweetUrl(tweetUrl) {
    const match = tweetUrl.match(
      /^https?:\/\/x\.com\/([A-Za-z0-9_]+)\/status\/\d+$/
    );
    return match ? match[1] : null; // Extract the username if the URL is valid
  }
  function validateTweetUrl() {
    const tweetUrlInput = document.getElementById("tweet_url_1").value.trim();
    if (!tweetUrlInput) {
      console.log("Please enter a Tweet URL.", "white");
      return null;
    }

    let tweetUrl = tweetUrlInput;
    if (!tweetUrl.startsWith("http://") && !tweetUrl.startsWith("https://")) {
      tweetUrl = "https://" + tweetUrl;
    }

    const match = tweetUrl.match(
      /^https?:\/\/x\.com\/([A-Za-z0-9_]+)\/status\/(\d+)$/
    );
    if (!match) {
      console.log("Invalid Tweet URL format.", "white");
      return null;
    }

    return tweetUrl;
  }
});
// CONFESSION
document.addEventListener("DOMContentLoaded", () => {
  const submitButton = document.getElementById("submit-confess");
  const textArea = document.getElementById("comment-box");

  submitButton.addEventListener("click", async () => {
    const confession = textArea.value.trim();

    if (!confession) {
      alert("Please write your confession before submitting.");
      return;
    }

    try {
      const response = await fetch("/api/generate-response", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ confession }),
      });

      const data = await response.json();

      if (response.ok) {
        // alert(`Generated response:\n${data.response}`);
        textArea.value = ""; // Clear the textarea after submission
        document.getElementById("confess-box").classList.add("none");
        document.getElementById("mainContainer").classList.add("none");
        animateText("speechtextid", "I FORGIVE YOU...", 100);
        bless.play();
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error("Error submitting confession:", error);
      alert("Something went wrong. Please try again.");
    }
  });
});

// ADMIN
