import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import GUI from "lil-gui";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { gsap } from "gsap";

/**
 * Loaders
 */

const loadingBarElement = document.querySelector(".loading");

let sceneReady = false;
let innerBody = null;
let bikini = null;
let dress = null;

const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    setTimeout(() => {
      loadingBarElement.classList.add("hidden"); // Add class to start fade-out animation
      setTimeout(() => {
        loadingBarElement.style.display = "none"; // Remove loading div after animation ends
      }, 500); // Match the transition duration in CSS (0.5s)
    }, 1000); // Delay before starting the fade-out animation (optional)

    // window.setTimeout(() => {
    //   blackOverlay.classList.add("ended");
    //   blackOverlay.style.transform = "";
    //   sceneReady = true;
    // }, 2000);
  }

  // Progress
);

const gltfLoader = new GLTFLoader(loadingManager);
const rgbeLoader = new RGBELoader(loadingManager);
const textureLoader = new THREE.TextureLoader(loadingManager);
/**
 * Base
 */

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Update all materials
 */
const updateAllMaterials = () => {
  scene.traverse((child) => {
    if (child.isMesh) {
      child.castShadow = true;
      child.receiveShadow = true;
    }
  });
};

/**
 * Environment map
 */
// Intensity
scene.environmentIntensity = 1;
// gui.add(scene, "environmentIntensity").min(0).max(10).step(0.001);

// HDR (RGBE) equirectangular
rgbeLoader.load(
  "./environmentMaps/0/2k.hdr",
  (environmentMap) => {
    environmentMap.mapping = THREE.EquirectangularReflectionMapping;
    scene.environment = environmentMap;
  },
  undefined,
  (error) => {
    console.error("Error loading environment map:", error);
  }
);
/**
 * Directional light
 */
const directionalLight = new THREE.DirectionalLight("#ffffff", 6);
directionalLight.position.set(5, 5, 5);
scene.add(directionalLight);
scene.fog = new THREE.Fog(0xcccccc, 10, 15);
// Shadows
directionalLight.castShadow = true;

// directionalLight.castShadow = true;
// directionalLight.shadow.camera.far = 15;
// directionalLight.shadow.normalBias = 0.027;
// directionalLight.shadow.bias = -0.004;
directionalLight.shadow.mapSize.set(1024, 1024);

// Target
directionalLight.target.position.set(0, 4, 0);
directionalLight.target.updateWorldMatrix();

// // Helper

// Animation mixer
let mixer;
let action;
const maxTime = 6.25; // Time equivalent to 150 frames at 24 FPS
/**
 * Models
 */
// Shadow Plane

const shadowMat = new THREE.ShadowMaterial({ opacity: 0.25 }); // ShadowMaterial for transparent shadows
let actions = {};
let currentAction;
const enableShadowsOnAllMeshes = (scene) => {
  scene.traverse((child) => {
    if (child.isMesh) {
      child.castShadow = true; // Enable casting shadows
      child.receiveShadow = true; // Enable receiving shadows
    }
  });
};
gltfLoader.load("/models/cardnal5.glb", (gltf) => {
  gltf.scene.scale.set(2.5, 2.5, 2.5);
  gltf.scene.position.y -= 3.9;
  // gltf.scene.position.x -= 2.15;
  scene.add(gltf.scene);
  // enableShadowsOnAllMeshes(gltf.scene);
  // Set up AnimationMixer
  mixer = new THREE.AnimationMixer(gltf.scene);

  // Extract the desired animation
  const animationClip = THREE.AnimationClip.findByName(
    gltf.animations,
    "Armature|mixamo.com|Layer0"
  );

  if (animationClip) {
    // Create and play the animation action
    const action = mixer.clipAction(animationClip);
    action.setLoop(THREE.LoopRepeat); // Loop the animation
    action.play();
  } else {
    console.error("Animation not found: Armature|mixamo.com|Layer0");
  }
});

// Helper function to wait for the current animation to finish

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}
// Initialize variables for tracking actions
let actionStep = 0; // Tracks the current action step

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera aspect and frustum
  const aspectRatio = sizes.width / sizes.height;
  camera.left = -cameraFrustumSize * aspectRatio;
  camera.right = cameraFrustumSize * aspectRatio;
  camera.top = cameraFrustumSize;
  camera.bottom = -cameraFrustumSize;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
// Base camera
const aspectRatio = sizes.width / sizes.height;
const cameraFrustumSize = 5; // Customize based on the scene's size and scale

const camera = new THREE.OrthographicCamera(
  -cameraFrustumSize * aspectRatio, // Left
  cameraFrustumSize * aspectRatio, // Right
  cameraFrustumSize, // Top
  -cameraFrustumSize, // Bottom
  0.1, // Near
  100 // Far
);
camera.zoom += 3;
camera.updateProjectionMatrix();
camera.position.set(0, 1, 4); // Position the camera in the scene
camera.lookAt(0, 0, 0); // Make the camera look at the origin
scene.add(camera);

// Controls
// const controls = new OrbitControls(camera, canvas);
// // controls.target.y = 3.5;
// controls.enableDamping = true;

// controls.dampingFactor = 0.05; // Adjust the damping factor for smoothness
// controls.enablePan = false;
// controls.enableZoom = false;
// // Lock Y-axis rotation
// controls.minPolarAngle = Math.PI / 2.5; // Minimum angle (90 degrees)
// controls.maxPolarAngle = Math.PI / 2.5; // Maximum angle (90 degrees)
// // Limit X-axis (horizontal) rotation to 180 degrees
// controls.minAzimuthAngle = -Math.PI / 2.5; // -90 degrees
// controls.maxAzimuthAngle = Math.PI / 2.5; // 90 degrees

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});
renderer.setClearColor(0x1c1c1c, 0);
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

// Tone mapping
renderer.toneMapping = THREE.NoToneMapping;
renderer.toneMappingExposure = 3;

// Shadows
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;

/**
 * Animate
 */
// Clock
const clock = new THREE.Clock();

const tick = () => {
  // Update controls
  // controls.update();

  // Update mixer if it's defined
  if (mixer) mixer.update(clock.getDelta() * 0.4);
  // Animate smoke

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
